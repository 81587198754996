import React from 'react';
import './Status-style.css';

const Status = ({ value, map }) => {
    const [text, color] = map[value] || ['Not Assigned', '#6a7276'];
    return (
        <span className="status" style={{ color }}>
            {text}
        </span>
    );
};
export default Status;
