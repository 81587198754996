const env = process.env.NODE_ENV || 'development';
const target = process.env.REACT_APP_TARGET || 'dev';
const apiUrls = {
    production: {
        dev: 'https://dev-api-onlineshop.localizedirect.com',
        prod: 'https://api-order.localizedirect.com'
    },
    development: {
        dev: 'https://dev-api-onlineshop.localizedirect.com',
        local: 'http://api.ld.local'
    }
};
export const API_URL = apiUrls[env][target];

const gridlyConfig = {
    prod: {
        host: 'https://api.gridly.com/gridly',
        QAChecklist: {
            apiKey: 'QUFBQUFBQUFBQnM9ISYjfjo7JycrQUFBQmNzQ2NxZ1k9',
            path:
                '/api/v1/app/databases/505029b26c6a473ca3773337bad1879f/views/defaultAccessView_c2106e2c454b4bfaadfb340aa2003158/records',
            columns: [
                'vdc6d98ad841147d29643a739b6dfcba4',
                'x069b151e1d714c629789544b1100c615',
                '3e7faa4834c340fea27ab1c82ac58c44',
                '9a51aad3dd934d7bb2618c2d4ae939a6',
                'a314b3e70f1b43789ff9ce264860520e',
                '2dacd0129f98499f9d8c6edf23c39d61',
                'b20583c6e46f494183f81248ea51fa2d',
                '45c098e974ea4ee2b167c79cace0d30e',
                'ae8f4a931c724e6ea4011f7bea10b810',
                '38b961afa9fa441fa297ea1cc09ecadd',
                '14466725dae7411fa10ecfd06f827173',
                'ba73543f15b146b684383d242d27ee5a',
                'cc9ddf32915e48b1bebb9c92d2753d4e',
                '28116d479c42412ea9f6bf13408a7495',
                '650295d92e84485bab7240050793e4a0',
                '36cb2f425bcd4174b159ed1d2725a11f',
                '714343a318bd40ea8cd0ae008437ad57'
            ]
        }
    },
    dev: {
        host: 'https://gateway.staging.gridly.com/gridly',
        QAChecklist: {
            apiKey: 'QUFBQUFBQUFBRjQ9ISYjfjo7JycrQUFBQmNsVTlsazQ9',
            path:
                '/api/v1/databases/b552605ea9354c7b8f9c81dfbb7cc08b/views/defaultAccessView_e64e38e1550b47348a5e96df1c3f7807/records',
            columns: [
                'me8514c40e8b64c95b1f6542295d24b05',
                'm963c4b84d03c4237a5f8ce4bcee5a054',
                'a84ba4c675ec4418e80a598a33821bb90',
                'a570766c5a55b4c9d96062619aafa67e9',
                'l2d16449460694d72bb005ea0abfee9f6',
                'r1e6fd759446142f193b90a5b424b3b56',
                'm0a430e1bf601448ea4f0e91f62e369bd',
                'aa88fb1693c8e41a189991ccfee491c6d',
                'sb0d1c34366074e6ca4e69a9729071a5b',
                'i476a2e9eae4e4e1ea8537d706f696108',
                'n95a6fa4f5297441eaac4254b178e2c4e',
                'vbe563a6236f74bb8861e0af969e498c8',
                'k6e46a97c7f4644f7968035f36cc858d2',
                'i1272f37f776b47bfbca45e362b705a58',
                'e0b53ece4fa2c46d8baf4d500dc13e0b5',
                'i289560c9fcca450db4fabe1f3182d6a2',
                'jf5bbf4bd559e44beaa9a9eef93a7eb78'
            ]
        }
    }
};

export const GRIDLY_CONFIG = gridlyConfig[target];

export const APP_NAME = 'TMS';

export const PAGES = {
    HOME: '/',
    ORDERS: '/orders',
    ORDER: '/orders/:id',
    USERS: '/users',
    CUSTOMER: '/customers',
    CHECKLIST: '/checklists',
    DELIVERY: '/delivery',
    EXTRACTED: '/extracted-file'
};

export const AUTHED_PAGE_HOME = '/';

export const THEMES = {
    BLUE: 'blue',
    INDIGO: 'indigo',
    PURPLE: 'purple',
    PINK: 'pink',
    RED: 'red',
    ORANGE: 'orange',
    YELLOW: 'yellow',
    GREEN: 'green',
    TEAL: 'teal',
    CYAN: 'cyan',
    DARK: 'dark',
    LIGHT: 'light'
};

export const PAYMENT_PROVIDER = {
    STRIPE: 'stripe',
    PAYPAL: 'paypal'
};
